<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP SERVICE REQUEST LIST

type    : view

uses    : header-list
          main-container
          table-status

route   : /serviceRequests (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-service-request-list">
    <header-list
      title="Service Requests"
      short_title="All SR"
      :btnOptions="[
        {
          name: 'New Service Request',
          action: () => $router.push('/serviceRequests/create'),
        },
      ]" />
    <main-container id="serviceRequestTableContainer">
      <div>
        <v-card>
          <v-card-title>
            <v-btn
              height="28"
              class="button-primary"
              @click="filterdialog = true">
              <span>Filter</span>
              <v-icon right small>mdi-filter</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="filteredServiceRequests"
            class="elevation-1"
            id="serviceRequestTable"
            sort-by="number"
            sort-desc
            :loading="busy">
            <template v-slot:item="props">
              <tr
                @click="
                  $router.push('/serviceRequests/view/' + props.item.uuid)
                ">
                <td>SR-{{ props.item.number.toString().padStart(5, '0') }}</td>
                <td>{{ props.item.requestor_name }}</td>
                <td>
                  <!-- {{ props.item.client_name ? props.item.client_name : "Unknown"}} -->
                  {{ props.item.client_name }}
                </td>
                <td>
                  {{ displayDateFormat(props.item.date_submitted, dateFormat) }}
                </td>
                <td class="text-center">
                  <table-status :status="props.item.service_request_status" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar color="info" flat dark>
            <v-toolbar-title>Filter Service Requests</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Contact Name Contains"
                    v-model="requestor_name"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Client Name Contains"
                    v-model="client_name"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px">
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="date_submitted_greater"
                        label="Date Submitted Greater than"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_submitted_greater"
                      @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px">
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="date_submitted_less"
                        label="Date Submitted Less than"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_submitted_less"
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <div id="status-filter">
                    <v-select
                      v-model="service_request_status"
                      :items="filterItems"
                      filled
                      label="Request Status"></v-select>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button-primary" text @click="filterdialog = false"
              >Apply</v-btn
            >
            <v-btn class="button-secondary" text @click="clearFilter()"
              >Clear</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';
  import HeaderList from '@/components/header-list';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppServiceRequestList',
    components: {
      'header-list': HeaderList,
      'main-container': MainContainer,
      'table-status': TableStatus,
    },
    mixins: [dateTimeHelpers, Localization],
    data() {
      return {
        menu1: false,
        menu2: false,
        filterdialog: false,
        requestor_name: null,
        client_name: null,
        date_submitted_greater: null,
        date_submitted_less: null,
        service_request_status: null,
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Approved',
            value: 'Approved',
          },
          {
            text: 'Submitted',
            value: 'Submitted',
          },
          {
            text: 'Declined',
            value: 'Declined',
          },
          {
            text: 'Cancelled Only',
            value: 'Cancelled',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'SR ID',
            value: 'number',
          },
          {
            text: 'Contact Name',
            value: 'requestor_name',
          },
          {
            text: 'Client',
            value: 'client_name',
          },
          {
            text: 'Date Submitted',
            value: 'date_submitted',
          },
          {
            text: 'Request Status',
            value: 'service_request_status',
            align: 'center',
          },
        ],

        serviceRequests: [],
      };
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      // a computed getter
      filteredServiceRequests: function () {
        // console.log( this.serviceRequests )

        // `this` points to the vm instance
        var filtered = this.serviceRequests.filter(
          function (serviceRequest) {
            let pass = true;
            if (
              this.requestor_name &&
              !serviceRequest.requestor_name
                .toLowerCase()
                .includes(this.requestor_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.client_name &&
              !serviceRequest.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.date_submitted_greater &&
              serviceRequest.date_submitted < this.date_submitted_greater
            ) {
              pass = false;
            }
            if (
              this.date_submitted_less &&
              serviceRequest.date_submitted > this.date_submitted_less
            ) {
              pass = false;
            }
            if (
              this.service_request_status &&
              serviceRequest.service_request_status !=
                this.service_request_status
            ) {
              pass = false;
            }

            return pass;
          }.bind(this)
        );

        // console.log( filtered );
        return filtered;
      },
    },
    async created() {},
    async mounted() {
      this.busy = true;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get service requests
      var service_requests = await Jobs.getServiceRequests(
        {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
        },
        accessToken
      );

      // validate and assign
      if (service_requests) {
        this.serviceRequests = service_requests;

        // get clients
        var clients = await Clients.getClients(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
          },
          accessToken
        );

        // look through clients, so we know which client the estimate is for
        if (clients) {
          // console.log( "clients:", clients )

          for (var i = 0; i < clients.length; i++) {
            for (var j = 0; j < this.serviceRequests.length; j++) {
              if (clients[i].uuid == this.serviceRequests[j].client_uuid) {
                // console.log( "samesies:", clients[ i ].uuid, this.serviceRequests[ j ].client_uuid )
                this.serviceRequests[j].client_name = clients[i].client_name;
                // console.log( this.serviceRequests[ j ].client_name, clients[ i ].client_name )
              }
            }
          }
          this.busy = false;
        } else {
          this.busy = false;
          throw Error('Something went wrong');
        }
      } else {
        this.busy = false;
        throw Error('Something went wrong');
      }
    },
    methods: {
      clearFilter: function () {
        this.requestor_name = null;
        this.client_name = null;
        this.date_submitted_greater = null;
        this.date_submitted_less = null;
        this.service_request_status = null;
        this.filterdialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
